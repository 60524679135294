import React from 'react'

import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

import { FormHelperText } from '@mui/material'

const UserForm = ({
  form,
  handleChangeInput,
  getAdress,
  showCepAlert,

  isEmptyFormm
}) => {
  return (
    <div>
      <div className='alert-margin'>
        {showCepAlert && (
          <Alert severity='error' color='error'>
            Insira um CEP válido!
          </Alert>
        )}
      </div>

      <Box sx={{ marginBottom: 2 }}>
        <div className='alert-margin'>
          {isEmptyFormm && (
            <Alert severity='error' color='error'>
              Preencha todos os campos!
            </Alert>
          )}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Nome'
              value={form.nome}
              onChange={e => handleChangeInput('nome', e.target.value)}
              variant='outlined'
              name='nome'
              fullWidth
              error={isEmptyFormm && form.nome === ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='CPF'
              variant='outlined'
              name='cpf'
              onChange={e => handleChangeInput('cpf', e.target.value)}
              value={form.cpf}
              fullWidth
              error={isEmptyFormm && form.cpf === ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Email'
              name='email'
              value={form.email}
              variant='outlined'
              onChange={e => handleChangeInput('email', e.target.value)}
              fullWidth
              error={isEmptyFormm && form.email === ''}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={2}>
        <Grid item xs={2} sm={1}>
            <InputMask
              mask="99"
              value={form.ddd}
              onChange={(e) => handleChangeInput('ddd', e.target.value)}
            >
              {() => (
                <TextField
                  label="DDD"
                  name="ddd"
                  variant="outlined"
                  fullWidth
                  error={isEmptyFormm && form.ddd === ''}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={8} sm={4}>
            <InputMask
              mask="99999-9999"
              value={form.telefone}
              onChange={(e) => handleChangeInput('telefone', e.target.value)}
            >
              {() => (
                <TextField
                  label="Telefone"
                  name="telefone"
                  variant="outlined"
                  fullWidth
                  error={isEmptyFormm && form.telefone === ''}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label='Data Nascimento'
              name='data_nascimento'
              onChange={e => handleChangeInput('data_nascimento', e.target.value)}
              variant='outlined'
              value={form.data_nascimento}
              fullWidth
              error={isEmptyFormm && form.data_nascimento === ''}
            />
            <FormHelperText>*Apenas Numeros, formatação automática</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Senha Gov'
              name='senha_gov'
              value={form.senha_gov}
              onChange={e => handleChangeInput('senha_gov', e.target.value)}
              variant='outlined'
              fullWidth
              error={isEmptyFormm && form.senha_gov === ''}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label='CEP'
              value={form.cep}
              variant='outlined'
              name='cep'
              fullWidth
              onChange={e => handleChangeInput('cep', e.target.value)}
              onBlur={getAdress}
              error={isEmptyFormm && form.cep === ''}
            />
          </Grid>
          <Grid item xs={12} sm={2.7}>
            <TextField
              label='Endereço'
              value={form.logradouro}
              variant='outlined'
              name='logradouro'
              fullWidth
              onChange={e => handleChangeInput('logradouro', e.target.value)}
              error={isEmptyFormm && form.logradouro === ''}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <TextField
              label='Bairro'
              value={form.bairro}
              variant='outlined'
              name='bairro'
              fullWidth
              onChange={e => handleChangeInput('bairro', e.target.value)}
              error={isEmptyFormm && form.bairro === ''}
            />
          </Grid>
          <Grid item xs={12} sm={1.8}>
            <TextField
              label='Cidade'
              value={form.municipio}
              variant='outlined'
              name='municipio'
              fullWidth
              onChange={e => handleChangeInput('municipio', e.target.value)}
              error={isEmptyFormm && form.municipio === ''}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <TextField
              label='Numero'
              value={form.numero}
              variant='outlined'
              name='numero'
              fullWidth
              onChange={e => handleChangeInput('numero', e.target.value)}
              error={isEmptyFormm && form.numero === ''}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <TextField
              label='Complemento'
              value={form.complemento}
              variant='outlined'
              name='complemento'
              fullWidth
              onChange={e => handleChangeInput('complemento', e.target.value)}
              // error={isEmptyFormm && form.complemento === ''}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default UserForm

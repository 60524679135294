// Components

import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

import { useState, useEffect, useCallback } from "react";

import IntlCurrencyInput from "react-intl-currency-input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { getAllCnaes } from "../service/serviceOpenMei";

const cnaesPermitidos = [
  { value: "7319002" },
  { value: "4743100" },
  { value: "4742300" },
  { value: "4541206" },
  { value: "4530703" },
  { value: "4744001" },
  { value: "4744002" },
  { value: "4744003" },
  { value: "4744004" },
  { value: "4744099" },
  { value: "4751201" },
  { value: "4752100" },
  { value: "4753900" },
  { value: "4754701" },
  { value: "4754702" },
  { value: "4754703" },
  { value: "4755501" },
  { value: "4756300" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4789099" },
  { value: "4782202" },
  { value: "4783101" },
  { value: "4783102" },
  { value: "4785799" },
  { value: "4789001" },
  { value: "4789002" },
  { value: "4789003" },
  { value: "4789004" },
  { value: "4789005" },
  { value: "4789005" },
  { value: "4789007" },
  { value: "4789008" },
  { value: "4757100" },
  { value: "4759801" },
  { value: "4759899" },
  { value: "4763601" },
  { value: "4763602" },
  { value: "4763603" },
  { value: "4763604" },
  { value: "4772500" },
  { value: "4772500" },
  { value: "4773300" },
  { value: "4774100" },
  { value: "4781400" },
  { value: "4782201" },
];

const options = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
];

const renderUf = () => {
  return options.map((el) => {
    return (
      <MenuItem key={el.value} value={el.value}>
        {el.label}
      </MenuItem>
    );
  });
};

const atuacoes = [
  { id: 0, label: 'Estabelecimento Fixo' },
  { id: 1, label: 'Internet' },
  { id: 2, label: 'Local Fixo Fora da Loja' },
  { id: 3, label: 'Correio' },
  { id: 4, label: 'Porta a porta, postos móveis ou por ambulantes' },
  { id: 5, label: 'Televenda' },
  { id: 6, label: 'Máquinas automáticas' }
]


const formasAtuacao = () => {
  return atuacoes.map((el) => {
    return (
      <MenuItem key={el.id} value={el}>
        {el.label}
      </MenuItem>
    );
  });
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const EmpresaForm = ({
  form,
  handleChangeInput,
  handleAutocompleteChange,
  handleAtividadeSecundaria,
  isEmptyFormm,
  isEmptyCapitalSocial,
  handleChangeCapitalSocial,
}) => {
  const mystyle = {
    fontFamily: "Inter, san-serif",
    fontSize: "1rem",
    placeholder: "Capital Social",
    letterSpacing: "inherit",
    color: "#383838",
    border: "1px solid #c4c4c4 ",
    background: "none",
    paddingLeft: "10px",
    padding: "19px 25px",
    borderRadius: "6px",
    borderColor: isEmptyCapitalSocial ? "red" : "#c4c4c4",
  };

  const [cnaeOptions, setCnaeOptions] = useState([]);

  const fetchCnaes = useCallback(async () => {
    const response = await getAllCnaes();

    setCnaeOptions(response.data.cnaes);
  }, []);

  useEffect(() => {
    fetchCnaes();
  }, [fetchCnaes]);

  const cnaesPermitidosValues = cnaesPermitidos.map((cnae) => cnae.value);

  const cnaesFiltrados = cnaeOptions.filter((el) =>
    cnaesPermitidosValues.includes(el.cnae)
  );

  const emptyCurrencyValue = "0.00";

  return (
    <div>
      <div className="alert-margin">
        {isEmptyFormm && (
          <Alert severity="error" color="error">
            Preencha todos os campos!
          </Alert>
        )}
      </div>
      <div className="alert-margin">
        {isEmptyCapitalSocial && (
          <Alert severity="error" color="error">
            Capital Social nao pode ser 0,00
          </Alert>
        )}
      </div>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="RG"
              variant="outlined"
              onChange={(e) => handleChangeInput("rg", e.target.value)}
              value={form.rg}
              inputProps={{
                maxLength: 9,
              }}
              fullWidth
              error={isEmptyFormm && form.rg === ""}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              label="Orgão Emissor"
              name="orgao_emissor"
              onChange={(e) =>
                handleChangeInput("orgao_emissor", e.target.value)
              }
              value={form.orgao_emissor}
              variant="outlined"
              fullWidth
              error={isEmptyFormm && form.orgao_emissor === ""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="mes-das-select">UF Emissor</InputLabel>
              <Select
                label="Estado"
                value={form.uf_emissor}
                labelId="uf-emissor"
                name="uf_emissor"
                error={isEmptyFormm && form.uf_emissor === ""}
                onChange={(e) =>
                  handleChangeInput("uf_emissor", e.target.value)
                }
              >
                {renderUf()}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Atuação</InputLabel>
              <Select
                label="Atuação"
                value={form.forma_atuacao}
                labelId="forma_atuacao"
                name="forma_atuacao"
                onChange={(e) =>
                  handleChangeInput("forma_atuacao", e.target.value)
                }
              >
                {formasAtuacao()}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl
              fullWidth
              error={
                isEmptyCapitalSocial &&
                form.capital_social === emptyCurrencyValue
              }
            >
              <IntlCurrencyInput
                required
                style={mystyle}
                currency="BRL"
                value={form.capital_social}
                config={currencyConfig}
                onChange={handleChangeCapitalSocial}
              />
              <FormHelperText>Capital Social</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl
              fullWidth
              error={isEmptyFormm && form.atividade_principal === ""}
            >
              <Autocomplete
                name="atividade_principal"
                value={form.atividade_principal}
                options={cnaesFiltrados}
                getOptionLabel={(option) => (option ? option.ocupacao : "")}
                onChange={handleAutocompleteChange}
                id="autocomplete-controlled"
                renderInput={(params) => (
                  <TextField {...params} label="Atividade Principal" />
                )}
              />
              <FormHelperText>*Escolha a atividade principal</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth>
              <Autocomplete
                name="atividades_secundarias"
                value={form.atividades_secundarias}
                options={cnaesFiltrados}
                multiple
                getOptionLabel={(option) => (option ? option.ocupacao : "")}
                onChange={handleAtividadeSecundaria}
                id="autocomplete-controlled"
                renderInput={(params) => (
                  <TextField {...params} label="Atividade Secundaria" />
                )}
              />
              <FormHelperText>*Escolha a atividade secundaria</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EmpresaForm;
